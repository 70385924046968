import { TextareaAutosize } from '@mui/material'
import React from 'react'

export default function Textarea({ placeholder, label, className, ...rest }) {
    return (
        <>
            {label && <label >{label}</label>}
            <TextareaAutosize minRows={8} {...rest} className={className} placeholder={placeholder} />
        </>
    )
}
