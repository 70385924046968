import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LabeledInput from '../textfield/inputField';
import CustomButton from '../button/filledButton';

export default function ForgotModal({ open, handleClose }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: 1050, padding: 4, outline:"0", margin: 'auto', mt: '10%', backgroundColor: 'white', boxShadow: 24 }}>

                <Box className="forgotPassword">
                    <h6>Forgot Password <span onClick={handleClose}>x</span></h6>
                    <span>We are here to help you, Enter the email id to get reset link</span>
                    <LabeledInput className="forgotInput" type="text " label="Registered Email Id" />
                    <CustomButton className="btnConfirm">Confirm</CustomButton>
                </Box>

                {/* Close button */}
                {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleClose} variant="contained" color="primary">
                        Close
                    </Button>
                </Box> */}
            </Box>
        </Modal>
    );
}
