import { Box } from '@mui/material'
import React from 'react'
import Sidebar from './sidebar'
import HeaderPage from './header'

export default function LayoutHoc({ children }) {
    return (
        <>
            <Box className="mainLoayout">
                <Sidebar />
                <HeaderPage />
                <Box className="layoutMain">
                    {children}
                </Box>
            </Box>
        </>
    )
}
