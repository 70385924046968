import React, { useState } from 'react'
import LayoutHoc from '../../component/layout'
import { Box,  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import User from "../../assets/images/user.png"
import Contact from "../../assets/images/contact.png"
import Invoice from "../../assets/images/invoice.png"
import Cube from "../../assets/images/cube.png"
export default function Dashboard() {

  const invoices = [
    { customer: 'Satyam Singh', invoiceNo: '3367787', invoiceDate: '11/01/23', dueDate: '23/01/26', qty: 1, tax: '20.00%' },
    { customer: 'Deepak Singh', invoiceNo: '3367787', invoiceDate: '11/01/23', dueDate: '23/01/26', qty: 1, tax: '20.00%' },
    { customer: 'Saurabh Singh', invoiceNo: '3367787', invoiceDate: '11/01/23', dueDate: '23/01/26', qty: 1, tax: '20.00%' },
    { customer: 'Vivek Shrivastava', invoiceNo: '3367787', invoiceDate: '11/01/23', dueDate: '23/01/26', qty: 1, tax: '20.00%' },
    // Additional rows can be added here for testing pagination
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <LayoutHoc>
      <div className="App">
        <Box className="dashboardPgae">
          <h3>Dashboard</h3>
          <Box className="dashCard" sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <Box className="customer">
              <img src={User} alt="" />
              <Box>
                <h5>Total Customers</h5>
                <h5>200</h5>
              </Box>
            </Box>
            <Box className="customer">
              <img src={Contact} alt="" />
              <Box>
                <h5>Total Contacts</h5>
                <h5>200</h5>
              </Box>
            </Box>
            <Box className="customer">
              <img src={Invoice} alt="" />
              <Box>
                <h5>Total Invoice</h5>
                <h5>200</h5>
              </Box>
            </Box>
          </Box>
        </Box>
        <h2 className="invoice">Latest Invoices</h2>
        <Box className="dashboardTable">
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table sx={{ minWidth: "100%", width: "100%" }} aria-label="simple table">
              <TableHead className='theadTable'>
                <TableRow>
                  <TableCell>Customer</TableCell>
                  <TableCell>Invoice No.</TableCell>
                  <TableCell>Invoice Date</TableCell>
                  <TableCell>Due Date</TableCell>
                  <TableCell>Qty.</TableCell>
                  <TableCell>Tax</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='theadBody'>
                {invoices
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((invoice, index) => (
                    <TableRow key={index}>
                      <TableCell>{invoice.customer}</TableCell>
                      <TableCell>{invoice.invoiceNo}</TableCell>
                      <TableCell>{invoice.invoiceDate}</TableCell>
                      <TableCell>{invoice.dueDate}</TableCell>
                      <TableCell>{invoice.qty}</TableCell>
                      <TableCell>{invoice.tax}</TableCell>
                      <TableCell>
                        <img src={Cube} alt="" />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>

          </TableContainer>
        </Box>
        {/* <TablePagination
          sx={{ width: "1056px" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={invoices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </div>
    </LayoutHoc>
  )
}
