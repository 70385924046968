import React from 'react';
import { Button } from '@mui/material';

export default function CustomButton({ className, children, ...rest }) {
  return (
    <Button className={className} {...rest}>
      {children}
    </Button>
  );
}

