import React, { useState } from 'react';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function LabeledInput({ label, placeholder, type, className, ...rest }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl sx={{ width: '100%' }} className={className} variant="outlined">
      <InputLabel>{label}</InputLabel>
      {type === 'date' ? (
        <TextField
          type="date"
          placeholder={placeholder}
          variant="outlined"
          label={label}
          InputLabelProps={{ shrink: true }}
          {...rest}
        />
      ) : (
        <OutlinedInput
        placeholder={placeholder}
          type={type === 'password' && !showPassword ? 'password' : 'text'}
          endAdornment={
            type === 'password' ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : null
          }
          label={label}
          {...rest}
        />
      )}
    </FormControl>
  );
}
