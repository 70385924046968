import React, { useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { Box } from '@mui/material';
import Search from "../../assets/images/search.png"
import Date from "../../assets/images/date.png"


const DateRangePicker = () => {
    const [value, setValue] = useState();

    return (
        <Box className="dateRangePicker yearWise" style={{position:"relative"}}>
            <img src={Search} alt="" className='searchimg' />
            <DatePicker
                value={value}
                onChange={setValue}
                range
                placeholder="Search Between Dates"
            />
            <img src={Date} alt="" className='dateimg' />
        </Box>
    );
};

export default DateRangePicker;
