import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Logo from "../../../assets/images/logo.png";
import { challan, chevron, credit, dashboard, invoice, sales, voucher } from '../../../utils/svg_file';
import { Link, useLocation } from 'react-router-dom';

export default function Sidebar() {
  const [show, setShow] = useState(false);
  const location = useLocation();

//   useEffect(() => {
//     const paths = ['/sales', '/delivery-challan', '/credit-note'];
//     setShow(paths.includes(location.pathname));
//   }, [location.pathname]);
useEffect(() => {
    const creditNotePaths = ['/credit-note', '/create-credit-note', '/sales', '/create-invoice', '/delivery-challan', '/create-delivery', ];
    setShow(creditNotePaths.some(path => location.pathname.startsWith(path)));
  }, [location.pathname]);
  useEffect(() => {
    const creditVouchPaths = ['/create-voucher'];

  }, [location.pathname]);

  return (
    <>
      <Box className="sideBar">
        <Box className="logoSidebar">
          <img src={Logo} alt="" />
        </Box>
        <Box className="sidebarList">
          <ul>
            <Link to="/dashboard">
              <li className={location.pathname === '/dashboard' ? 'active-link' : ''}>
                <span>{dashboard}</span> Dashboard
              </li>
            </Link>
            <Box>
              <li onClick={() => setShow(!show)}>
                <span>{sales}</span> Sales <span>{chevron}</span>
              </li>
              {show && (
                <Box className="innerList">
                  <Link to="/sales">
                    <li className={location.pathname === '/sales' || location.pathname.includes('/create-invoice') ? 'active-link' : ''}>
                      <span>{invoice}</span> Sales Invoices
                    </li>
                  </Link>
                  <Link to="/delivery-challan">
                    <li className={location.pathname === '/delivery-challan' || location.pathname.includes('/create-delivery') ? 'active-link' : ''}>
                      <span>{challan}</span> Delivery Challan
                    </li>
                  </Link>
                  {/* Update the active-link class for credit note */}
                  <Link to="/credit-note">
                    <li
                      className={
                        location.pathname === '/credit-note' || location.pathname.includes('/create-credit-note')
                          ? 'active-link'
                          : ''
                      }
                    >
                      <span>{credit}</span> Credit Notes
                    </li>
                  </Link>
                </Box>
              )}
            </Box>
            <Link to="/voucher">
              <li className={location.pathname === '/voucher' || location.pathname.includes('/create-voucher') ? 'active-link' : ''}>
                <span>{voucher}</span> Voucher
              </li>
            </Link>
            <Link to="/subscription">
              <li className={location.pathname === '/subscription' ? 'active-link' : ''}>
                <span>{voucher}</span> Subscription Plan
              </li>
            </Link>
          </ul>
        </Box>
      </Box>
    </>
  );
}