import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const BasicSelect = ({ label, data, value, onChange }) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <label>{label}</label>
      <FormControl fullWidth>
        <TextField
          select
          value={value}
          onChange={onChange}
          variant="outlined"
        >
          {data.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Box>
  );
};

export default BasicSelect;
