import { Box } from '@mui/material'
import React, { useState } from 'react'
import { chevron, lines } from '../../../utils/svg_file'
import { Link } from 'react-router-dom';

export default function HeaderPage() {
    const [show, setShow] = useState(false);
    const [collapse, setCollapse] = useState(false);
    return (
        <>
            <Box className={collapse ?"headerBar active" : "headerBar"}>
                <Box className="headerList">
                    <h2>{lines}</h2>
                    <Box className="dropdownHeader">
                        <Box onClick={() => setShow(!show)} className="listDrop">
                            <span>D</span>
                            {chevron}
                        </Box>
                        {show && <Box className="logout">
                            <ul>
                              <Link to="/"> <li>Log out</li></Link> 
                            </ul>
                        </Box>}
                    </Box>
                </Box>
            </Box>
        </>
    )
}
