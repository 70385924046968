import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Pagination,

} from '@mui/material';
import Cube from "../../assets/images/cube.png"
import LayoutHoc from '../../component/layout';
import Back from "../../assets/images/back.png";
import Add from "../../assets/images/add.png"
import FilterDropdown from '../../component/filterDropdown';
import DateRangePicker from '../../component/datepicker';
import BasicSelect from '../../component/selectDropdown';
import { Link } from 'react-router-dom';

const invoices = [
  { date: '11/01/23', invoiceNo: '3367787', partyName: 'Deepak Singh',  amount: 'Rs. 30000', cheque: '20000' },
  { date: '11/01/23', invoiceNo: '3367787', partyName: 'Satyam Singh', amount: 'Rs. 30000',cheque: '20000'  },
  { date: '11/01/23', invoiceNo: '3367787', partyName: 'Saurabh Singh', amount: 'Rs. 30000', cheque: '20000'},
  { date: '11/01/23', invoiceNo: '3367787', partyName: 'Vivek Shrivastava',  amount: 'Rs. 30000',cheque: '20000'  },
  { date: '11/01/23', invoiceNo: '3367787', partyName: 'Yuvraj Shinde', amount: 'Rs. 30000', cheque: '20000' },
  { date: '11/01/23', invoiceNo: '3367787', partyName: 'Abhishek Singh',  amount: 'Rs. 30000', cheque: '20000' },
];

function Voucher() {
  const data = ['30 days', '60 days', '90 days'];
  const [billTo, setBillTo] = useState('30 days');

  const handleBillToChange = (event) => {
    setBillTo(event.target.value);
  };


  return (
    <LayoutHoc>
      <div className="Sales">
        <h3><img src={Back} alt="" /> Voucher</h3>
      </div>
      <Box className="filters">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FilterDropdown />
          <DateRangePicker />
        </Box>
       <Link to="/create-voucher"><Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={Add} alt="" />&nbsp;
          <h2 className='createInvoice'>Create Voucher</h2>
        </Box></Link> 
      </Box>


      <TableContainer component={Paper} className="tableBox">
        <Table sx={{ minWidth: "100%", width: "100%" }} aria-label="simple table">
          <TableHead className='theadTable'>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Voucher  No.</TableCell>
              <TableCell>Party Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Cheque No.</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='theadBody'>
            {invoices
              .map((invoice, index) => (
                <TableRow key={index}>
                  <TableCell>{invoice.date}</TableCell>
                  <TableCell>{invoice.invoiceNo}</TableCell>
                  <TableCell>{invoice.partyName}</TableCell>
                  <TableCell>{invoice.amount}</TableCell>
                  <TableCell>{invoice.cheque}</TableCell>
                  <TableCell>
                    <img src={Cube} alt="" />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={invoices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </TableContainer>
      <Box className="pagination">
        <Box className="selectField">
          <BasicSelect
            label="Records Per Page"
            data={data}
            value={billTo}
            onChange={handleBillToChange}
          />
        </Box>
        <Pagination count={10} />
      </Box>
    </LayoutHoc>
  );
}

export default Voucher;
