import React, { useState } from 'react'
import LayoutHoc from '../../component/layout'
import { Box, Grid, TextField, Typography } from '@mui/material'
import Back from "../../assets/images/back.png";
import Printer from "../../assets/images/printer.png";
import Plus from "../../assets/images/plus.png";
import Vicon from "../../assets/images/v.png";
import BasicSelect from '../../component/selectDropdown';
import LabeledInput from '../../component/textfield/inputField';
import Addtable from './component/add-table';
import CustomButton from '../../component/button/filledButton';
import Textarea from '../../component/textfield/textarea';
import { settings } from '../../utils/svg_file';

export default function CreateDeliveryChallan() {
    const customers = ['Please select a customer name','Deepak Singh', 'Satyam Singh', 'Saurabh Singh'];
    const data = [ '30 days', '60 days', '90 days'];
    const [billTo, setBillTo] = useState('Please select a customer name');
    const [days, setDays] = useState('30 days');
    const [shipTo, setShipTo] = useState('Please select a customer name');

    const handleBillToChange = (event) => {
        setBillTo(event.target.value);
    };

    const handleShipToChange = (event) => {
        setShipTo(event.target.value);
    };
    const handleDaysToChange = (event) => {
        setDays(event.target.value);
    };
    return (
        <LayoutHoc>
            <Box className="Sales">
                <h3><img src={Back} alt="" />Create Delivery Challan</h3>
                <img src={Printer} alt="" style={{
                    position: "relative",
                    bottom: "5px",
                    cursor: "pointer"
                }} />
            </Box>
            <Box className="cardInvoice">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box className="fieldBox">
                            <BasicSelect
                                label="Bill To"
                                data={customers}
                                value={billTo}
                                onChange={handleBillToChange}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="fieldBox">
                            <BasicSelect
                                label="Ship To"
                                data={customers}
                                value={shipTo}
                                onChange={handleShipToChange}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="fieldBox labels">
                        <label style={{display:"flex", justifyContent:"space-between"}}>Challan No. <span>{settings}</span></label>
                            
                            <LabeledInput label="3367787" type="text" />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="fieldBox">
                            <label>Challan Date</label>
                            <LabeledInput type="date" />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="fieldBox">
                            <label>Tax</label>
                            <LabeledInput type="date" />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box className="fieldBox">
                            <BasicSelect
                                label="Payment Terms"
                                data={data}
                                value={days}
                                onChange={handleDaysToChange}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box className="fieldBox">
                            <label>Due Date</label>
                            <LabeledInput type="date" />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="fieldBox labels">
                            <label>Address</label>
                            <LabeledInput label="Indranagar" type="text" />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="fieldBox labels">
                            <label>Vehicle Number</label>
                            <LabeledInput label="GJ78AC45650" type="text" />
                        </Box>
                    </Grid>
                </Grid>


            </Box>
            <Box className="muiTables">
                <Addtable />
            </Box>
            <Box className="saleData">
                <Box className="containers">
                    <Box className="chargeAdd">
                        <h6>Add Additional Charges</h6>
                        <h6>0.00</h6>
                    </Box>


                    <Box className="rows">
                        <h6>Taxable Amount</h6>
                        <Box className="taxBox">
                            <TextField variant="outlined" className='counterText' size="small" fullWidth value="0.00" InputProps={{ readOnly: true }} />
                            <TextField variant="outlined" className='ruppeeText' size="small" select SelectProps={{ native: true }}>
                                <option>Rs</option>
                                <option>$</option>
                                <option>€</option>
                            </TextField>
                        </Box>
                        <h6>-0.00</h6>
                    </Box>
                    <Box className="rows">
                        <h6>Add Discount</h6>
                        <Box className="taxBox">
                            <TextField variant="outlined" className='counterText-1' size="small" fullWidth value="0.00" InputProps={{ readOnly: true }} />
                        </Box>
                        <h6>-0.00</h6>
                    </Box>

                    <CustomButton className="applyTcs">Apply TCS</CustomButton>
                    <hr />
                    <Box className="rows">
                        <h6>Auto Round Off</h6>
                        <Box className="taxBox">
                            <TextField variant="outlined" className='counterText-1' size="small" fullWidth value="0.00" InputProps={{ readOnly: true }} />
                        </Box>
                        <h6>-0.00</h6>
                    </Box>

                    <Box className="totalAmount">
                        <Typography variant="h6">Total Amount</Typography>
                        <TextField variant="outlined" size="small" fullWidth placeholder="Enter payment amount" />
                    </Box>
      
                    <hr />
                    <Box sx={{textAlign:"end"}}>
                        <CustomButton className="signature-button"><img src={Plus} alt="" />&nbsp; Add Signature</CustomButton>
                    </Box>

                </Box>
            </Box>
            <Box className="textBox">
                <Textarea className="textArea" placeholder="Enter any notes to be displayed in your transactions." label="Customer Notes" />
            </Box>
            <Box className="textBox">
                <Textarea className="textArea" placeholder="Enter any notes to be displayed in your transactions." label="Terms & Conditions" />
            </Box>
            <Box className="btnSave">
                <CustomButton><img src={Vicon} alt="" /> Save</CustomButton>
            </Box>
        </LayoutHoc>
    )
}
