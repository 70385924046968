import { Route, Routes } from 'react-router-dom';
import './App.css';
import LoginPage from './pages/login';
import Dashboard from './pages/dashboard';
import Sales from './pages/sales';
import Invoice from './pages/invoice';
import CreateInvoice from './pages/create-invoice';
import DeliveryChallan from './pages/delievery-challan';
import CreateDeliveryChallan from './pages/create-delivery-challan';
import CreditNote from './pages/credit-note';
import CreateCreditNote from './pages/create-credit-note';
import Voucher from './pages/voucher';
import Subscription from './pages/subscription';
import CreateVoucher from './pages/create-voucher';

function App() {
  return (
    <Routes>

      <Route path='/' element={<LoginPage />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/sales' element={<Sales />} />
      <Route path='/invoice' element={<Invoice />} />
      <Route path='/create-invoice' element={<CreateInvoice />} />
      <Route path='/delivery-challan' element={<DeliveryChallan />} />
      <Route path='/create-delivery' element={<CreateDeliveryChallan/>} />
      <Route path='/credit-note' element={<CreditNote/>} />
      <Route path='/create-credit-note' element={<CreateCreditNote/>} />
      <Route path='/voucher' element={<Voucher/>} />
      <Route path='/subscription' element= {<Subscription/>} />
      <Route path='/create-voucher' element={<CreateVoucher/>} />
    </Routes>
  );
}

export default App;
