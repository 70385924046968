import React from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import Chevron from "../../assets/images/chev.png";
import Arrow from "../../assets/images/arrow.png"

const FilterDropdown = () => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        className='buttonFilter'
        onClick={handleClick}
      >
        <img src={Chevron} alt="" />
        Filters 
        <img src={Arrow} alt="" className='arrows' />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Option 1</MenuItem>
        <MenuItem onClick={handleClose}>Option 2</MenuItem>
        <MenuItem onClick={handleClose}>Option 3</MenuItem>
      </Menu>
    </div>
  );
};

export default FilterDropdown;
