import React, { useState } from 'react';
import CustomButton from "../../component/button/filledButton";
import { Box } from '@mui/material';
import LayoutHoc from '../../component/layout';
import Back from "../../assets/images/back.png"

const cardData = [
  {
    id: 1,
    title: '$30.00',
    heading: 'Basic',
    month: 'month',
    features: [
      'Loreum ipsum sit dolor emit',
      'Loreum ipsum sit dolor emit',
      'Loreum ipsum sit dolor emit',
      'Loreum ipsum sit dolor emit',
      'Loreum ipsum sit dolor emit',
    ],
    price: '19.90',
    duration: 'Month',
  },
  {
    id: 2,
    title: 'Free',
    heading: 'Free',
    features: [
      'Loreum ipsum sit dolor emit',
      'Loreum ipsum sit dolor emit',
      'Loreum ipsum sit dolor emit',
      'Loreum ipsum sit dolor emit',
      'Loreum ipsum sit dolor emit',
    ],
    price: '19.90',
    duration: 'Year',
  },
  // Add more cards as needed
];

export default function Subscription() {
  const defaultActiveIndex = 0;
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);

  const handleChoose = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <LayoutHoc>
           <div className="Sales">
        <h3><img src={Back} alt="" /> Subscription Plan</h3>
      </div>
    <Box className="paymentSection" sx={{marginTop:"20px"}}>
      {cardData.map((card, index) => (
        <Box key={index} className={activeIndex === index ? 'cards activeCard' : 'cards'} onClick={() => handleChoose(index)}>
          {card.id === 1 && (
            <Box className="premiumBtn">
              <CustomButton>Premium</CustomButton>
            </Box>
          )}
          <h3>{card.title}{card.month && <span>/{card.month}</span>}</h3>
          <Box className="descriptionMain">
            <h5>{card.heading}</h5>
            <p>Loreum epsum sit dolor emit</p>
          </Box>
          <ul>
            {card.features.map((feature, featureIndex) => (
              <li key={featureIndex}>
                <Box style={{ display: "flex", alignItems: "baseline" }}>
                  <Box> <p> {feature}</p> </Box>
                </Box>
              </li>
            ))}
          </ul>
          <Box style={{ padding: "10px 6px 5px" }}>
            <CustomButton className="btnChoose">
             {activeIndex === index ? "Current Plan" : "Choose Plan"}
            </CustomButton>
          </Box>
          {card.month ? <h6 className="planDate">This Plan Renews On<span> 12/02/2024 </span></h6> : ""}
        </Box>
      ))}
    </Box>
    </LayoutHoc>
  );
}
