import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Box } from '@mui/material';
import Add from "../../../../assets/images/addc.png";
import Cube from "../../../../assets/images/cube.png"


const initialRow = {
  itemService: 'Type or click to select an item.',
  hsnSac: '1',
  qty: '0',
  price: '1000',
  discount: '0.00',
  tax: '10%',
  amount: '2000'
};

const Addtable = () => {
  const [rows, setRows] = useState([initialRow]);

  const handleAddRow = () => {
    setRows([...rows, initialRow]);
  };

  return (
    <TableContainer component={Paper}>
      <Table className="custom-table">
        <TableHead>
          <TableRow>
            <TableCell>Item Services</TableCell>
            <TableCell>HSN/SAC</TableCell>
            <TableCell>QTY</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Tax</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.itemService}</TableCell>
              <TableCell>{row.hsnSac}</TableCell>
              <TableCell>{row.qty}</TableCell>
              <TableCell>{row.price}</TableCell>
              <TableCell>{row.discount}</TableCell>
              <TableCell>{row.tax}</TableCell>
              <TableCell>{row.amount}</TableCell>
              <TableCell>
                <Box>

                  <img src={Cube} alt="" style={{ marginRight: "10px" }} />
                  <img src={Add} alt="" />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Addtable;
