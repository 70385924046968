import React from 'react'
import LayoutHoc from '../../component/layout'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Back from "../../assets/images/back.png";
import CustomButton from '../../component/button/filledButton';
import { download } from '../../utils/svg_file';


export default function CreateVoucher() {
    return (
        <LayoutHoc>
            <Box className="Sales">
                <h3><img src={Back} alt="" />Voucher No : 3367787</h3>
                <CustomButton className="btnVoucher"><span>{download}</span> Download Voucher </CustomButton>
            </Box>
            <Box className="vocuherText">
                <h6>Issue Date: <span> 21 May, 2024 </span></h6>
                <h6>Voucher Type: <span>Journal Voucher </span> </h6>
            </Box>
            <Box className="vocuherText">
                <h6>Transaction By: <span> Journal voucher  </span></h6>
                <h6>Cheque No:  <span>674940575839 </span> </h6>
            </Box>

            <Box className="muiTables" sx={{ margin: "30px 0" }}>
                <TableContainer component={Paper}>
                    <Table className="custom-table addField">
                        <TableHead>
                            <TableRow>
                                <TableCell>Receipt </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>Payment(Expenses)</TableCell>
                                <TableCell></TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow >
                                <TableCell>SRN</TableCell>
                                <TableCell>Particulars</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Particulars</TableCell>
                                <TableCell>Amount</TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>1.</TableCell>
                                <TableCell>OPD Receipts</TableCell>
                                <TableCell>500</TableCell>
                                <TableCell>Tea</TableCell>
                                <TableCell>100</TableCell>

                            </TableRow>
                            <TableRow >
                                <TableCell>2.</TableCell>
                                <TableCell>OPD Receipts</TableCell>
                                <TableCell>500</TableCell>
                                <TableCell>Tea</TableCell>
                                <TableCell>1000</TableCell>

                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{ position: "relative" }} className="paidBox">
                <Box className="recieptBox">
                    <h5>Bank Receipt <span>2666</span></h5>
                    <h5>Credit Sales  <span>5657</span></h5>
                    <h5>Net Cash In Hand <span>4556</span></h5>
                    <Box className="totalAmountPaid">
                        <h6>Total Amount <span>6066</span></h6>
                    </Box>
                </Box>
            </Box>
        </LayoutHoc>
    )
}
