import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import Loginimg from "../../assets/images/login.png";
import Logo from "../../assets/images/logo.png";
import LabeledInput from '../../component/textfield/inputField';
import CustomButton from '../../component/button/filledButton';
import ForgotModal from '../../component/modal/forgotPassword';
import { Link } from 'react-router-dom';

export default function LoginPage() {
    const [openForgotModal, setOpenForgotModal] = useState(false);

    const handleOpenForgotModal = () => setOpenForgotModal(true);
    const handleCloseForgotModal = () => setOpenForgotModal(false);

    return (
        <>
            <Box className="loginPage">
                <Grid container spacing={2} sx={{ height: "100%", minHeight: "100vh", alignItems: "center" }}>
                    <Grid item xs={6}>
                        <Box>
                            <img src={Loginimg} alt="" />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="rightBox">
                            <img src={Logo} alt="" />
                            <Box>
                                <h4>Welcome Back !</h4>
                                <h6>LogIn to your Account</h6>
                                <Box className="loginForm">
                                    <Box>
                                        <LabeledInput className="loginInput" label="Email or Phone Number" type="text" />
                                    </Box>
                                    <Box>
                                        <LabeledInput className="loginInput" label="Password" type="password" />
                                    </Box>
                                    <Box sx={{ textAlign: "end" }}>
                                        <span className='forgotLink' onClick={handleOpenForgotModal}>Forgot Password?</span>
                                    </Box>
                                    <Box sx={{ marginTop: "12px" }}>
                                      <Link to="/dashboard">  <CustomButton className="loginBtn">Login</CustomButton></Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <ForgotModal open={openForgotModal} handleClose={handleCloseForgotModal} />
        </>
    );
}
