import React from 'react'
import LayoutHoc from '../../component/layout'
import { Box } from '@mui/material'
import Back from "../../assets/images/back.png";
import Download from "../../assets/images/download.png";
import Bill from "../../assets/images/bill.png";
import Share from "../../assets/images/share.png";
import Filter from "../../assets/images/chev.png";
import Pdf from "../../assets/images/pdf.png";
import CustomButton from '../../component/button/filledButton';

export default function Invoice() {
    return (
        <LayoutHoc>
            <Box>
                <Box className="Sales">
                    <h3><img src={Back} alt="" />Invoice</h3>
                </Box>
                <Box className="filterBoxButton">
                    <CustomButton><img src={Download} alt="" /> Download PDF</CustomButton>
                    <CustomButton><img src={Share} alt="" /> Share</CustomButton>
                    <CustomButton><img src={Bill} alt="" /> Generate E-way Bill</CustomButton>
                    <CustomButton><img src={Filter} alt="" /> Generate e-Invoice</CustomButton>
                </Box>
                <Box className="pdfBox">
                    <img src={Pdf} alt="" />
                </Box>
            </Box>
        </LayoutHoc>
    )
}
